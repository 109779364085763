import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';
import { RootState } from 'Util/Store/Store.type';
/** @namespace Inov8/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
